import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "content" ]

  connect() {
    $(this.contentTarget).toc({
      startLevel: 'h2'
    });
  }
}
